import {
  Box,
  Divider,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { FiBox, FiBriefcase } from 'react-icons/fi';

export default function Login() {
  return (
    <HStack h={'100vh'} overflow='hidden'>
      {/* Left Hand Image */}
      <Stack
        display={{ base: 'none', md: 'flex' }}
        w='50%'
        h='100%'
        position='relative'
        color='white'
      >
        <Image
          src='/images/stock1.jpg'
          alt='Procura Background'
          w='full'
          h='full'
          objectPosition='center'
          objectFit='cover'
        />
        <Box
          position='absolute'
          p='16'
          top='0'
          left='0'
          w='full'
          h='full'
          bg='linear-gradient(to bottom, rgba(0,0,0,0.75), transparent)'
        >
          <Heading size='xl' letterSpacing='wider'>
            All Procura.
            <br />
            One Platform.
          </Heading>
        </Box>
      </Stack>

      {/* Right Hand Login Form */}
      <Stack
        w={{ base: '100%', md: '50%' }}
        h='100%'
        p='8'
        alignItems='center'
        overflowX='hidden'
        overflowY='auto'
      >
        <Stack
          w='full'
          maxW='sm'
          h='full'
          textAlign='center'
          alignItems='center'
        >
          <Image src='/images/brand-logo.png' alt='Procura Logo' h='14' />
          <Heading
            size='lg'
            mt='8'
            mb='4'
            fontWeight='semibold'
            color='gray.800'
          >
            Hello User!
          </Heading>
          <Text fontSize='md' fontWeight='medium' color='gray.500'>
            Let&apos;s get you logged into your
            <br />
            Procura Account.
          </Text>

          <Stack w='full' gap='0' mt='8'>
            <Stack
              as={Link}
              href='/login/client'
              border='2px'
              borderColor='gray.300'
              color='gray.500'
              roundedTop='lg'
              transition='all 0.25s'
              textAlign='center'
              alignItems='center'
              w='full'
              p='8'
              borderBottomColor='transparent'
              _hover={{
                bg: 'gray.50',
                borderColor: 'procura-blue',
                color: 'procura-blue',
                borderBottom: '2px',
              }}
            >
              <Text fontSize='4xl'>
                <FiBriefcase />
              </Text>
              <Heading size='sm' fontWeight='semibold'>
                I am a Client
              </Heading>
            </Stack>

            <Divider borderColor='gray.300' />

            <Stack
              as={Link}
              href='/login/admin'
              border='2px'
              borderColor='gray.300'
              color='gray.500'
              roundedBottom='lg'
              transition='all 0.25s'
              textAlign='center'
              alignItems='center'
              w='full'
              p='8'
              borderTopColor='transparent'
              _hover={{
                bg: 'gray.50',
                borderColor: 'procura-blue',
                color: 'procura-blue',
                borderTop: '2px',
              }}
            >
              <Text fontSize='4xl'>
                <FiBox />
              </Text>
              <Heading size='sm' fontWeight='semibold'>
                I am an Admin
              </Heading>
            </Stack>
          </Stack>

          {/* <ChakraLink
            as={Link}
            href='/login/admin'
            color='gray.400'
            fontSize='sm'
            fontWeight='normal'
            mt='8'
            transition='all 0.25s'
            _hover={{ color: 'gray.700' }}
          >
            Are you a Procura Admin?{' '}
            <Text as='span' fontWeight='medium'>
              Login here
            </Text>
          </ChakraLink> */}
        </Stack>
      </Stack>
    </HStack>
  );
}

Login.getLayout = function getLayout(page) {
  return <Box>{page}</Box>;
};
